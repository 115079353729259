import {defineStore} from "pinia";
import {useUserStore} from '../../../Stores/User'
import {useConversation} from '../../../Stores/Conversation'
import {useAppState} from '../../../Stores/AppState'
import Utilities from '../../../Helpers/utilities'
import ArrayUtilities from '../../../Utilities/ArrayUtilities'

const REQUEST_TYPES = {
    SUPPORT: 'support',
    STATUS: 'request_status',
    ATTENTION: 'attention'
}

export const useRequest = defineStore('Request', {
    state: () => ({
        support: {
            icon: 'support_agent',
            message: 'Support requested from ',
            closed_message: 'Support Request Closed',
            message_to: 'Support requested to '
        },
        status: {
            icon: 'question_mark',
            message: 'Status requested from ',
            closed_message: 'Status Request Closed',
            message_to: 'Status requested to '
        },
        attention: {
            icon: 'priority_high',
            message: 'Attention requested from ',
            closed_message: 'Attention Request Closed',
            message_to: 'Attention requested'
        },
        urgent_request: [],
        urgent_attention_request_user_id: null,
        request_sent: [],
        urgent_request_with_timestamp: null,
        attention_request_snackbar: false,
        request_to_bounce: null,
        is_bounce: false
    }),
    getters: {},
    actions: {
        setAttentionRequestSnackbar(val) {
            this.attention_request_snackbar = val
        },

        setUrgentRequest(val) {
            this.urgent_request = val
        },

        removeUrgentRequestByIndex(index) {
            this.urgent_request.splice(index, 1)
        },

        removeUrgentRequestById(id) {
            const index = this.urgent_request.findIndex((req) => req.id === id)

            if (index >= 0)
                this.removeUrgentRequestByIndex(index)
        },

        setUrgentRequestByPush(val) {
            this.urgent_request.push(val)
        },

        setUrgentRequestWithTimestamp(val) {
            this.urgent_request_with_timestamp = val
        },

        closeRequest(conversation, id) {
            conversation.removeKaamfuRequest(id)
            this.removeUrgentRequestById(id)
        },

        closeAttentionRequest(conversation, requests, users) {
            requests.forEach(request => {
                conversation.removeKaamfuRequest(request?.id)
                this.removeUrgentRequestById(request?.id)
            });
            
            conversation.sendUrgentMessages(users, 'attention_closed', 'Attention Request Closed')

            // if (update_attribute)
            //     conversation.updateAttributes(urgent_request)
        },

        hasRequestWithTimestamp(id, timestamp, custom_data = null) {
            return (custom_data || this.urgent_request).findIndex(req => req.id === id && req.timestamp === timestamp)
        },

        hasRequest(id, custom_data = null) {
            return (custom_data || this.urgent_request).findIndex(req => req.id === id)
        },

        getRequestById(id) {
            return this.urgent_request.find(req => req.id === id)
        },

        isUserInAttentionRequest(user_id) {
            const index = this.hasRequest(id)
            if (index >= 0)
                return this.urgent_request[index].user.findIndex(req => req.id === user_id)
        },


        userGotAttentionRequest(id, model_id, request) {
            const attention = (request || this.urgent_request)?.find(req => this.isAttention(req?.type) && req?.user?.twilio_identity === id && req?.conv_id === model_id)
            return attention
        },

        sendRequest(conversation, user, type, skip = false) {
            const timestamp = Date.now()
            // const id = `${useUserStore().getTwilioIdentity}-${conversation.id}-${type}-${user.twilio_identity}`
            conversation.updateAttributes([this.setRequest(
                this.urgent_request,
                user,
                conversation.id,
                type,
                skip,
                null,
                timestamp
            )])
        },

        setRequest(request, user, conv_id, type, skip = false, id, timestamp) {
            const is_exist_request = request.find( req => 
                req?.user?.twilio_identity === user.twilio_identity && 
                req.type === type && 
                useUserStore().isCurrentUser(req?.from?.twilio_identity) &&
                req?.id === conv_id
            )

            if (is_exist_request && !skip)
                return is_exist_request

            const request_type = this.getRequestType(type)
            const new_timestamp = timestamp ?? Date.now()
            // const new_id = id ?? `${useUserStore().getTwilioIdentity}-${conv_id}-${type}-${user.twilio_identity}`

            const data = {
                // id: new_id,
                from: ArrayUtilities.clone(useUserStore().profile),
                user: user,
                type: type,
                icon: request_type.icon,
                message: request_type.message + useUserStore().user.first_name,
                message_to: request_type.message_to + (type !== 'attention' ? user.name : ''),
                closed_message: request_type.closed_message,
                model_id: conv_id,
                timestamp: new_timestamp
            }

            // this.request_sent.push(data)
            //request.push(data)
            return data
        },

        getRequestType(type) {
            switch (type) {
                case 'support':
                    return this.support

                case 'request_status':
                    return this.status

                default:
                    return this.attention
            }
        },

        isSentRequest(user, type, id, request) {
            if ((request || this.urgent_request).find(req =>
                req.user.twilio_identity === user.twilio_identity &&
                req.type === type &&
                req.model_id === id
            ))
                return true

            return false
        },

        openChat(conv_id) {
            const {toggleChatPopover} = Utilities()
            const app_state_store = useAppState()
            const conversation_store = useConversation()

            conversation_store.openConversationById(conv_id)
            const conversation = conversation_store.getConversationById(conv_id)

            toggleChatPopover(true)
            app_state_store.toggleInviteDrawer(false, conversation?.originalData?.item)
        },

        setAttentionUserId(id) {
            this.urgent_attention_request_user_id = id
        },


        updateUrgentRequest(requests = [], model_id = null) {
            if (model_id >= 0)
                this.urgent_request = this.urgent_request.filter(req => req.model_id != model_id)

            requests.forEach((req) => {
                if (useUserStore().isCurrentUser(req?.from?.twilio_identity) ||
                    useUserStore().isCurrentUser(req?.user?.twilio_identity)) {
                    const index = this.urgent_request.findIndex(req_sent => req_sent.id === req.id)

                    if (index < 0)
                        this.urgent_request.push(req)
                }
            })
        },

        // confirmedRequest(attention_request) {
        //     attention_request.forEach(ar => {
        //         const conversation = useConversation().getConversationById(ar.conv_id)
        //         const urgent_request = conversation.state.urgent_request
        //         urgent_request.forEach(req => req.id === ar.id && (req.confirmed = true))
        //         conversation.updateAttributes(urgent_request)
        //     })
        // },

        setRequestToBounce(val) {
            this.request_to_bounce = val
        },

        setIsBounce(val) {
            this.is_bounce = val
        },

        isAttention(request) {
            return request === REQUEST_TYPES.ATTENTION
        },

        isSupport(request) {
            return request === REQUEST_TYPES.SUPPORT
        },

        isStatus(request) {
            return request === REQUEST_TYPES.STATUS
        }
    }
})
